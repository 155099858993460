/* *
 * ========================================================================
 * Vortex Solution
 * ========================================================================
 * */

// Includes the custom Modernizr build based on the configurations set in .modernizrrc
require('Modernizr'); // eslint-disable-line import/no-extraneous-dependencies
require('nodelist-foreach-polyfill');
require('element-qsa-scope');
require('./main/dropdown-menu');
require('./main/mobile-menu');
require('./main/header-slider');
require('./main/media-text');
require('./main/user-is-tabbing');
require('./main/forms');
require('./main/image-lazy-load');

import vtxDomReady from './common/helpers';

vtxDomReady(() => {});
