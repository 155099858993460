import Swiper from 'swiper/bundle';

import 'swiper/css/bundle';

window.addEventListener('load', function () {
  let headerSlider = document.querySelector('.header-slider__carousel');
  let headerImagesSlider = document.querySelector(
    '.page-header__images__container'
  );

  if (headerSlider) {
    // carousel
    headerSlider = new Swiper('.header-slider__carousel', {
      clickable: true,
      loop: true,
      draggable: true,

      a11y: {
        paginationBulletMessage: 'Aller à la diapositive {{index}}',
        nextSlideMessage: 'Prochaine diapositive',
        prevSlideMessage: 'Diapositive précédente',
      },
      pagination: {
        el: '.header-slider__carousel__navigation .swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 3000,
      },

      breakpoints: {
        600: {
          pagination: {
            el: '.header-slider__carousel__navigation .swiper-pagination',
            clickable: true,
            type: 'bullets',
            renderBullet: function (index, className) {
              if (index < 9) {
                return (
                  '<span class="' + className + '">0' + (index + 1) + '</span>'
                );
              } else {
                return (
                  '<span class="' + className + '">' + (index + 1) + '</span>'
                );
              }
            },
          },
        },
      },
    });
  }
  if (headerImagesSlider) {
    // carousel
    headerImagesSlider = new Swiper('.page-header__images__container', {
      clickable: true,
      loop: true,
      draggable: true,
      effect: 'fade',

      a11y: {
        paginationBulletMessage: 'Aller à la diapositive {{index}}',
        nextSlideMessage: 'Prochaine diapositive',
        prevSlideMessage: 'Diapositive précédente',
      },
      autoplay: {
        delay: 6000,
      },
    });
  }
});
