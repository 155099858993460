let fields_are_initialized = false;

function make_fields_readonly() {
  const readOnlyTextFields = document.querySelectorAll(
    '.gfield.gf_readonly textarea, .gfield.gf_readonly input'
  );

  if (0 < readOnlyTextFields.length) {
    readOnlyTextFields.forEach(function (readOnlyTextField) {
      readOnlyTextField.setAttribute('readonly', 'readonly');
    });
  }
}

function change_form_label(label, input) {
  if ('' !== input.value) {
    label.classList.add('active');
  }

  label.addEventListener('click', function () {
    input.focus();
  });

  input.addEventListener('focus', function () {
    label.classList.add('active');
  });

  input.addEventListener('focusout', function () {
    if (!input.value || '(___) ___-____' === input.value) {
      label.classList.remove('active');
    }
  });
}

function add_form_events() {
  const fields = document.querySelectorAll(
    '.gfield:not(.gfield--radio):not(.gfield--select)'
  );

  if (0 < fields.length) {
    fields.forEach(function (field) {
      if (field.classList.contains('initialized')) {
        return;
      }

      if (field.classList.contains('gfield--fileupload')) {
        field.querySelector('.gform_drop_instructions').textContent =
          wp.i18n._x(
            'Drag and drop file to upload',
            'drag and drop instruction',
            'vtx'
          );
      }

      const classesExcluded = [
        'gfield--radio',
        'gfield--checkbox',
        'gfield--consent',
        'gfield--select',
      ];

      for (var i = 0; i < classesExcluded.length; i++) {
        if (field.classList.contains(classesExcluded[i])) {
          return;
        }
      }

      let label;
      let input;

      field.classList.add('initialized');

      label = field.querySelector('.gfield_label');
      input = field.querySelector(
        '.ginput_container input , .ginput_container textarea'
      );

      if (label && input) {
        change_form_label(label, input);
      }

      if (field.classList.contains('gfield--has-multiple-inputs')) {
        const subfields = field.querySelectorAll('.ginput_complex > span');

        if (0 < subfields.length) {
          subfields.forEach(function (subfield) {
            if (subfield.classList.contains('initialized')) {
              return;
            }
            subfield.classList.add('initialized');

            let sublabel = subfield.querySelector('label');
            let subinput = subfield.querySelector('input, textarea');
            change_form_label(sublabel, subinput);
          });
        }
      }
    });
  }
}

document.addEventListener('gform_post_render', function () {
  fields_are_initialized = true;
  add_form_events();
  make_fields_readonly();
});

// ON READY
window.addEventListener('load', function () {
  if (!fields_are_initialized) {
    // Initialize fields events only if they were not already.
    add_form_events();
    make_fields_readonly();
  }
});
